import { useState, useRef, useEffect } from "react";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { isMobile } from "react-device-detect";

import { useNavigate } from "react-router-dom";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import "bootstrap/dist/css/bootstrap.min.css";

import TermsView from "../terms/TermsView";
import add from "../../assets/add.svg";
import missing from "../../assets/missing.svg";
import heic from "../../assets/HEIC.jpg";

const Upload = (props) => {
  const navigate = useNavigate();
  const [showTerms, setShowTerms] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [activeKey, setActiveKey] = useState("0");
  const [preview, setPreview] = useState(false);
  const [file, setFile] = useState(false);
  const aRef = useRef(null);
  const [creator, setCreator] = useState("");
  const [county, setCounty] = useState("");
  const [place, setPlace] = useState("");
  const [maxYear, setMaxYear] = useState(false);
  const [allApproved, setAllApproved] = useState(false);
  const [message, setMessage] = useState("");

  const [showErr, setShowErr] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [termsApproved, setTermsApproved] = useState(false);
  const [sending, setSending] = useState(false);
  const [percent, setPercent] = useState(0);
  const [allDone, setAllDone] = useState(false);
  const [isSet, setIsSet] = useState(false);
  const contact = useRef(null);
  const up = useRef(null);

  const RequiresIframeScrollFix = () => {
    try {
      var is_safari = navigator.userAgent.indexOf("Safari") > -1;
      var is_chrome = navigator.userAgent.indexOf("Chrome") > -1;
      if (is_chrome && is_safari) {
        is_safari = false;
      }

      if (
        is_safari &&
        (navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPhone/i))
      )
        return true;
      else return false;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if ("parentIframe" in window) {
      window.parentIframe.resize();
    }
    if ("parentIFrame" in window) {
      window.parentIFrame.resize();
    }

    if ("parentIframe" in window) {
      window.parentIframe.scrollTo(0, 0);
    }
    if ("parentIFrame" in window) {
      window.parentIFrame.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    window.parent.postMessage("resizeIframe", "*");
    if ("parentIframe" in window) {
      window.parentIframe.resize();
    }
    if ("parentIFrame" in window) {
      window.parentIFrame.resize();
    }

    if (showTerms) {
      window.scrollTo(0, 0);
      if ("parentIframe" in window) {
        window.parentIframe.scrollTo(0, 0);
      }
      if ("parentIFrame" in window) {
        window.parentIFrame.scrollTo(0, 0);
      }
      if (!isMobile) {
        disableBodyScroll(up.current);
      }
    } else {
      enableBodyScroll(up.current);
    }
  }, [showTerms]);
  useEffect(() => {
    let h = window.screen.availHeight - 250;
    let w = window.screen.availWidth;
    // document.body.style.height = h + "px !important";
    // document.body.style.minHeight = h + "px !important";
    //document.body.style.maxHeight = h + "px !important";

    //window.parent.document.getElementById('foolsFrame').iFrameResizer.resize();
    //window.parent[0].parentIFrame.resize()
    if (up.current && !isSet) {
      setIsSet(true);
      if ("parentIframe" in window) {
        window.parentIframe.resize();
      }
      if ("parentIFrame" in window) {
        window.parentIFrame.resize();
      }
      window.parent.postMessage("resizeIframe", "*");
      // document.body.style.height = "0px !important";
      // document.body.style.minHeight = "0px !important";
      // document.body.style.height = h + "px !important";
      // document.body.style.minHeight = h + "px";
      // //up.current.style.minHeight = "0px";
      // up.current.style.height = "0px";
      // up.current.style.minHeight = h + "px";
      // up.current.style.height = h + "px !important";
    }
  }, [up.current]);
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    addFiles(files);
  };

  const addFiles = (files) => {
    setPercent(0);
    let allowed = 0;

    for (const f of files) {
      if (
        f.type === "image/jpeg" ||
        f.type === "image/jpg" ||
        f.type === "image/png" ||
        f.type === "image/heic"
      ) {
        allowed += 1;
      } else {
        setShowErr(true);
        window.scrollTo(0, 0);

        if ("parentIframe" in window) {
          window.parentIframe.scrollTo(0, 0);
        }
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollTo(0, 0);
        }
      }
    }
    if (allowed === files.length) {
      setFile(files[0]);
      setPreview(URL.createObjectURL(files[0]));
    }
  };
  const validateEmail = (e) => {
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    setEmail(e.target.value);
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const config = {
    onUploadProgress: (progressEvent) => {
      let progress = (progressEvent.loaded / progressEvent.total) * 100;
      setPercent(progress);
    },
  };

  useEffect(() => {
    if (
      name.length > 3 &&
      file &&
      phone.length > 7 &&
      email.length > 5 &&
      emailValid &&
      county.length >= 3 &&
      place.length >= 3 &&
      creator.length >= 2 &&
      maxYear &&
      allApproved &&
      termsApproved
    ) {
      setCanSend(true);
    } else {
      setCanSend(false);
    }
  }, [
    name,
    file,
    phone,
    email,
    emailValid,
    county,
    place,
    creator,
    maxYear,
    allApproved,
    termsApproved,
  ]);
  const sendit = () => {
    let fdata = new FormData();
    fdata.append("mediaFile", file);
    fdata.append("name", name);
    fdata.append("phone", phone);
    fdata.append("email", email);
    fdata.append("campaign", "1");
    fdata.append("area", county);
    fdata.append("image_text", place);
    fdata.append("photographer", creator);
    fdata.append("description", message);

    var url = "https://d2rea8vzk7rcz8.cloudfront.net/";
    axios
      .post(url + "uploadMedia", fdata, config)
      .then((res) => {
        if (res.data.statusCode == 200) {
          setTimeout(() => {
            setAllDone(true);
            if (RequiresIframeScrollFix()) {
              let t = contact.current.getBoundingClientRect().top;
              window.scrollTo(0, 0);
              if ("parentIframe" in window) {
                window.parentIframe.scrollTo(0, t + 80);
              }
              if ("parentIFrame" in window) {
                window.parentIFrame.scrollTo(0, t + 80);
              }
            } else {
              contact.current.scrollIntoView({ behavior: "smooth" });
            }

            // scrollIntoView(contact.current, {
            //   block: "start",
            //   inline: "nearest",
            //   behavior: "smooth",
            // });
          }, 1000);
        }
      })
      .catch((err) => {
        console.log("error: ", err.message);
      });
  };

  const clearAll = () => {
    setPreview(false);
    setFile(false);
    setCreator("");
    setCounty("blekinge");
    setPlace("");
    setMaxYear(false);
    setAllApproved(false);
    setMessage("");

    setCanSend(false);
    setTermsApproved(false);
    setSending(false);
  };
  useEffect(() => {
    if (allDone) {
      clearAll();
    }
  }, [allDone]);
  const getColor = (fromColor, toColor, percent) => {
    const delta = percent / 100;
    const r = Math.round(toColor.r + (fromColor.r - toColor.r) * delta);
    const g = Math.round(toColor.g + (fromColor.g - toColor.g) * delta);
    const b = Math.round(toColor.b + (fromColor.b - toColor.b) * delta);

    return `rgb(${r}, ${g}, ${b})`;
  };

  return (
    <div className="upload" ref={up}>
      <div className="mobile_label">
        <h2>
          Ladda upp ditt
          <br />
          tävlingsbidrag.
        </h2>
        <p>
          Skicka in en bild med Trygg-Hansas livboj där livbojen har en tydlig
          roll. Kom ihåg att juryn bedömer den kreativa höjden, så känn dig inte
          låst!
        </p>
      </div>

      <div className="block">
        <div className={allDone ? "form_block alldone" : "form_block"}>
          <Accordion
            activeKey={activeKey}
            onSelect={(e) => {
              setActiveKey(e + "");
            }}
            defaultActiveKey="0"
            ref={aRef}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header disabled={allDone}>
                {(file !== false || allDone) && (
                  <span className="sectionDone" />
                )}{" "}
                1. Ladda upp bild
              </Accordion.Header>
              <Accordion.Body>
                {preview === false && (
                  <>
                    <input
                      id="media"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/heic"
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          addFiles(e.target.files);
                        }
                      }}
                    />
                    <label
                      htmlFor="media"
                      className={!isDragging ? "uploadBox" : "uploadBox over"}
                      onDragEnter={handleDragEnter}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                    >
                      <div className="no-select no-pointer">
                        <img src={add} />
                        <p>
                          Släpp din bild här
                          <br />
                          eller <span>Välj bild</span>
                        </p>
                      </div>
                    </label>
                  </>
                )}

                {preview !== false && (
                  <div
                    className="picHolder"
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <div
                      style={{
                        backgroundImage:
                          "url(" +
                          (file.name.toLowerCase().indexOf(".heic") > 0
                            ? heic
                            : preview) +
                          ")",
                      }}
                      className="preview"
                    ></div>
                    <img
                      src={add}
                      onClick={() => {
                        setFile(false);
                        setPreview(false);
                      }}
                      className="close"
                    />
                    <p className="filename">
                      {file.name} - {(file.size / (1024 * 1024)).toFixed(2)}
                      mb
                    </p>
                  </div>
                )}
                <span className="allowed">
                  <p>Tilllåtna format: JPG,PNG,HEIC</p>
                </span>
                {preview !== false && (
                  <div className="btnrow">
                    <div
                      className={"thbtn"}
                      onClick={() => {
                        setActiveKey("1");
                      }}
                    >
                      Nästa steg
                    </div>
                    <div
                      className="thbtn blank"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Avbryt
                    </div>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header disabled={!file}>
                {((creator.length >= 2 &&
                  county.length >= 3 &&
                  place.length >= 3 &&
                  maxYear &&
                  allApproved) ||
                  allDone) && <span className="sectionDone" />}{" "}
                2. Filinformation
              </Accordion.Header>
              <Accordion.Body>
                <label
                  className="form-label"
                  htmlFor="name"
                  style={{ marginTop: 20 }}
                >
                  Fotograf
                </label>
                <input
                  id="name"
                  type="text"
                  value={creator}
                  onChange={(e) => {
                    setCreator(e.target.value);
                  }}
                  placeholder="Namn"
                />
                <label
                  className="form-label"
                  htmlFor="landskap"
                  style={{ lineHeight: "18px" }}
                >
                  Landskap (där bilden är tagen och livbojen finns)
                </label>
                <div className="select">
                  <select
                    className="county"
                    id="landskap"
                    name="area"
                    value={county}
                    onChange={(e) => {
                      if (e.target.value !== "-1") {
                        setCounty(e.target.value);
                      }
                    }}
                  >
                    <option value="" disabled>
                      Välj ort
                    </option>
                    <option value="blekinge">Blekinge</option>
                    <option value="bohuslän">Bohuslän</option>
                    <option value="dalarna">Dalarna</option>
                    <option value="dalsland">Dalsland</option>
                    <option value="gotland">Gotland</option>
                    <option value="gästrikland">Gästrikland</option>
                    <option value="halland">Halland</option>
                    <option value="hälsingland">Hälsingland</option>
                    <option value="härjedalen">Härjedalen</option>
                    <option value="jämtland">Jämtland</option>
                    <option value="lappland">Lappland</option>
                    <option value="medelpad">Medelpad</option>
                    <option value="norrbotten">Norrbotten</option>
                    <option value="närke">Närke</option>
                    <option value="skåne">Skåne</option>
                    <option value="småland">Småland</option>
                    <option value="södermanland">Södermanland</option>
                    <option value="uppland">Uppland</option>
                    <option value="värmland">Värmland</option>
                    <option value="västerbotten">Västerbotten</option>
                    <option value="västergötland">Västergötland</option>
                    <option value="västmanland">Västmanland</option>
                    <option value="ångermanland">Ångermanland</option>
                    <option value="öland">Öland</option>
                    <option value="östergötland">Östergötland</option>
                    <option value="-1">________________</option>
                    <option value="utlandet">Utomlands</option>
                  </select>
                  <span className="focus"></span>
                </div>
                <label className="form-label" htmlFor="place">
                  Var är bilden tagen?
                </label>
                <input
                  id="place"
                  type="text"
                  placeholder="Exempel: Min sommarstuga utanför Sunne, Värmland. "
                  value={place}
                  onChange={(e) => {
                    setPlace(e.target.value);
                  }}
                />
                <div className="checkholder">
                  <label className="checkcontainer">
                    Bilden är max ett år gammal.
                    <input
                      type="checkbox"
                      className="check"
                      id="maxyear"
                      name="maxyear"
                      required
                      checked={maxYear}
                      onChange={(e) => {
                        setMaxYear(e.target.checked);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="checkholder">
                  <label className="checkcontainer">
                    Eventuella människor som syns i bild har gett sitt
                    godkännande.
                    <input
                      type="checkbox"
                      className="check"
                      id="approval"
                      name="approval"
                      required
                      checked={allApproved}
                      onChange={(e) => {
                        setAllApproved(e.target.checked);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <label
                  className="form-label"
                  htmlFor="mess"
                  style={{ marginTop: 20, lineHeight: "18px" }}
                >
                  Berätta mer om din bild. Varför har du valt just denna livboj?
                </label>
                <textarea
                  id="mess"
                  value={message}
                  onChange={(e) => {
                    if (e.target.value.length <= 250) {
                      setMessage(e.target.value);
                    }
                  }}
                  placeholder="Exempel: Denna livboj betyder mycket för mig då det var här min dotter lärde sig simma förra sommaren."
                />
                <p
                  className={message.length === 250 ? "counter err" : "counter"}
                >
                  {message.length}/250 Tecken
                </p>
                {/*<div className="btnrow">
                  <div
                    className={
                      creator.length >= 2 &&
                      county.length >= 3 &&
                      place.length >= 3 &&
                      maxYear &&
                      allApproved
                        ? "thbtn"
                        : "thbtn disabled"
                    }
                    onClick={() => {
                      if (
                        creator.length >= 2 &&
                        county.length >= 3 &&
                        place.length >= 3 &&
                        maxYear &&
                        allApproved
                      ) {
                        //go
                      }
                    }}
                  >
                    Nästa steg
                  </div>
                  <div className="thbtn blank">Avbryt</div>
                </div>*/}
                <div className="btnrow">
                  <div
                    className={
                      creator.length >= 2 &&
                      county.length >= 3 &&
                      place.length >= 3 &&
                      maxYear &&
                      allApproved
                        ? "thbtn"
                        : "thbtn disabled"
                    }
                    onClick={() => {
                      if (
                        creator.length >= 2 &&
                        county.length >= 3 &&
                        place.length >= 3 &&
                        maxYear &&
                        allApproved
                      ) {
                        setActiveKey("2");
                        window.parent.postMessage("resizeIframe", "*");

                        window.scrollTo(0, 0);
                        let t = contact.current.getBoundingClientRect().top;

                        if ("parentIframe" in window) {
                          window.parentIframe.scrollTo(0, 80);
                        }
                        if ("parentIFrame" in window) {
                          window.parentIFrame.scrollTo(0, 80);
                        }

                        // scrollIntoView(contact.current, {
                        //   block: "start",
                        //   inline: "nearest",
                        //   behavior: "smooth",
                        // });
                      }
                    }}
                  >
                    Nästa steg
                  </div>
                  <div
                    className="thbtn blank"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Avbryt
                  </div>
                </div>
                <input type="hidden" id="campaign" name="campaign" value="1" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" ref={contact}>
              <Accordion.Header
                disabled={
                  sending
                    ? true
                    : !file ||
                      county.length <= 5 ||
                      place.length < 3 ||
                      creator.length <= 2 ||
                      !maxYear ||
                      !allApproved
                }
              >
                {(email.length >= 3 &&
                  emailValid &&
                  name.length >= 3 &&
                  phone.length >= 7 &&
                  termsApproved) ||
                  (allDone && <span className="sectionDone" />)}{" "}
                3. Kontaktuppgifter
              </Accordion.Header>
              <Accordion.Body>
                {allDone && (
                  <div className="done">
                    <h2>Tack för ditt bidrag!</h2>
                    <p>
                      Vi kommer att kontakta dig om du går vidare i tävlingen.
                    </p>
                    <div
                      className={"thbtn"}
                      onClick={() => {
                        setFile(false);
                        setPreview(false);
                        setPlace("");
                        setCreator("");
                        setMessage("");
                        setCounty("blekinge");
                        setMaxYear(false);
                        setAllApproved(false);
                        setActiveKey("0");
                        setAllDone(false);
                      }}
                    >
                      Ladda upp en till bild
                    </div>
                    <div
                      className={"thbtn"}
                      style={{
                        marginTop: 20,
                        background: "unset",
                        color: "gray",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        setFile(false);
                        setPreview(false);
                        setPlace("");
                        setCreator("");
                        setMessage("");
                        setCounty("blekinge");
                        setMaxYear(false);
                        setAllApproved(false);
                        setActiveKey("0");
                        setAllDone(false);
                        navigate("/");
                      }}
                    >
                      Till startsidan
                    </div>
                  </div>
                )}
                {!allDone && (
                  <>
                    <label className="form-label" htmlFor="your_name">
                      Namn
                    </label>
                    <input
                      id="your_name"
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      placeholder="Ditt namn"
                    />
                    <label className="form-label" htmlFor="email">
                      E-post
                    </label>
                    <input
                      id="email"
                      value={email}
                      onChange={validateEmail}
                      className={
                        !emailValid ? "notvalid pressable" : "pressable"
                      }
                      type="text"
                      placeholder="namn@abc.se"
                    />
                    <label className="form-label" htmlFor="phone">
                      Telefonnummer
                    </label>
                    <input
                      id="phone"
                      type="text"
                      value={phone}
                      onChange={(e) => {
                        let str = e.target.value;
                        str = str.replace(/\D/g, "");
                        setPhone(str);
                      }}
                      placeholder="0701234567"
                    />
                    <div className="checkholder">
                      <label className="checkcontainer">
                        Jag godkänner{" "}
                        <span
                          className="terms"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowTerms(true);
                            return false;
                          }}
                        >
                          tävlingsvillkoren
                        </span>
                        <input
                          type="checkbox"
                          className="check"
                          id="terms"
                          name="terms"
                          checked={termsApproved}
                          onChange={(e) => {
                            setTermsApproved(e.target.checked);
                          }}
                          required
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    {file !== false && sending && (
                      <div className="progressRow">
                        <p className="name">{file.name}</p>

                        <span>
                          <p className="currentPercent">
                            Laddar {Math.round(percent)}
                          </p>
                          <p> av 100%</p>
                        </span>

                        <div className="barHolder">
                          <div
                            className="bar"
                            style={{
                              width: `${percent}%`,
                              background: getColor(
                                { r: 43, g: 183, b: 65 },
                                { r: 128, g: 136, b: 249 },
                                percent,
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                    {!sending && (
                      <div className="btnrow">
                        <div
                          className={!canSend ? "thbtn inverted" : "thbtn"}
                          onClick={() => {
                            if (canSend) {
                              setSending(true);
                              sendit();
                            }
                          }}
                        >
                          Skicka
                        </div>
                        <div
                          className="thbtn blank"
                          onClick={() => {
                            clearAll();
                            setName("");
                            setPhone("");
                            setEmail("");
                            setEmailValid(false);
                            setActiveKey("0");
                            navigate("/");
                          }}
                        >
                          Avbryt
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="big_label">
          Ladda upp ditt
          <br />
          tävlingsbidrag.
          <p>
            Skicka in en bild med Trygg-Hansas livboj där livbojen har en tydlig
            roll. Kom ihåg att juryn bedömer den kreativa höjden, så känn dig
            inte låst!
          </p>
        </div>
      </div>
      {showTerms && (
        <TermsView
          closeTerms={() => {
            setShowTerms(false);
          }}
        />
      )}
      {showErr && (
        <div className="errView">
          <div className="errHolder">
            <img src={missing} />
            <h2>
              Filformatet stöds inte!
              <br />
              Välj en ny fil.
            </h2>
            <p>
              Filformat som stöds
              <br />
              JPG,PNG,HEIC
            </p>
            <div
              className={"thbtn"}
              onClick={() => {
                setShowErr(false);
              }}
            >
              OK
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Upload;
