import { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import RoutesRoot from "./components/global/Root";
import "./styles/App.scss";

const App = () => {
  return (
    <div className={isMobile ? "App Mobile" : "App Desk"}>
      <RoutesRoot />
    </div>
  );
};

export default App;
