import { useState, useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
import Accordion from "react-bootstrap/Accordion";
import { scrollIntoView } from "seamless-scroll-polyfill";

import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

import old from "../../assets/Bojen70.svg";

import pic1 from "../../assets/pics/1.jpg";
import pic2 from "../../assets/pics/2.jpg";
import pic3 from "../../assets/pics/3.jpg";

import g1 from "../../assets/group1.jpg";
//import g2 from "../../assets/group2.jpg";
import g2 from "../../assets/jury2.jpg";

import Masonry from "./modules/Masonry";
import TermsView from "../terms/TermsView";
let h = window.screen.availHeight - 300;
let w = window.screen.availWidth;

const Start = () => {
  const navigate = useNavigate();
  const startRef = useRef(null);
  const [showTerms, setShowTerms] = useState(false);
  const p1 = useRef(null);
  const p2 = useRef(null);
  const p3 = useRef(null);

  const block2 = useRef(null);

  const RequiresIframeScrollFix = () => {
    try {
      var is_safari = navigator.userAgent.indexOf("Safari") > -1;
      var is_chrome = navigator.userAgent.indexOf("Chrome") > -1;
      if (is_chrome && is_safari) {
        is_safari = false;
      }

      if (
        is_safari &&
        (navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPhone/i))
      )
        return true;
      else return false;
    } catch (e) {
      return false;
    }
  };
  useEffect(() => {
    // document.body.style.height = h + "px";
    // document.body.style.minHeight = h + "px";

    if ("parentIframe" in window) {
      window.parentIframe.resize();
    }
    if ("parentIFrame" in window) {
      window.parentIFrame.resize();
    }

    // window.scrollTo(0, 0);

    // if ("parentIframe" in window) {
    //   window.parentIframe.scrollTo(0, 0);
    // }
    // if ("parentIFrame" in window) {
    //   window.parentIFrame.scrollTo(0, 0);
    // }
  }, []);

  useEffect(() => {
    if ("parentIframe" in window) {
      window.parentIframe.scrollTo(0, 0);
    }
    if ("parentIFrame" in window) {
      window.parentIFrame.scrollTo(0, 0);
    }
  }, [window?.parentIframe, window?.parentIFrame]);

  useEffect(() => {
    if (showTerms) {
      // window.scrollTo(0, 0);
      // if ("parentIframe" in window) {
      //   window.parentIframe.scrollTo(0, 0);
      // }
      // if ("parentIFrame" in window) {
      //   window.parentIFrame.scrollTo(0, 0);
      // }
      window.scrollTo(0, 0);
      if ("parentIframe" in window) {
        window.parentIframe.scrollTo(0, 0);
      }
      if ("parentIFrame" in window) {
        window.parentIFrame.scrollTo(0, 0);
      }
      if (!isMobile) {
        disableBodyScroll(startRef.current);
      }
    } else {
      enableBodyScroll(startRef.current);
    }
  }, [showTerms]);

  useEffect(() => {
    if (p1.current && p2.current && p3.current) {
      gsap.to(p1.current, 1, {
        opacity: 1,
        top: 0,
        rotation: -10,
        x: -450,
        y: 30,
      });
      gsap.to(p2.current, 1, { opacity: 1, top: 0 });
      gsap.to(p3.current, 1, {
        opacity: 1,
        top: 0,
        rotation: 10,
        x: 450,
        y: 30,
      });
    }
  }, [p1.current, p2.current, p3.current]);
  //style={{ height: h + "px" }}
  return (
    <div className="start" ref={startRef}>
      <div className="block start">
        <div className="top">
          <div className="content">
            <img src={old} className="logo" />
            <h1>
              <span>Fototävling!</span>
              <br />
              Bästa livbojbilden
            </h1>
            {!isMobile && (
              <p>
                Nu hyllar vi vår livboj med en fototävling. Så släpp fram
                kreativiteten och skildra
                <br />
                Trygg-Hansas livsviktiga livboj genom kameralinsen på ditt unika
                sätt!
              </p>
            )}
            {isMobile && (
              <p>
                Nu hyllar vi vår livboj med en fototävling. Så släpp fram
                kreativiteten och skildra Trygg-Hansas livsviktiga livboj genom
                kameralinsen på ditt unika sätt!
              </p>
            )}
          </div>
          <div
            className="thbtn"
            onClick={() => {
              if (RequiresIframeScrollFix()) {
                let t = block2.current.getBoundingClientRect().top;
                if ("parentIframe" in window) {
                  window.parentIframe.scrollTo(0, t + 80);
                }
                if ("parentIFrame" in window) {
                  window.parentIFrame.scrollTo(0, t + 80);
                }
              } else {
                block2.current.scrollIntoView({ behavior: "smooth" });
              }
              return true;
            }}
          >
            Läs mer!
          </div>
        </div>

        <Masonry />
      </div>
      <div className="block" id="vinn" ref={block2}>
        <div className="content">
          {!isMobile && <h2>Visas din bild på den stora fotoutställningen?</h2>}
          {isMobile && (
            <h2>
              Visas din bild
              <br />
              på den stora fotoutställningen?
            </h2>
          )}
          <p>
            En vinnarbild från varje landskap koras och uppmärksammas vid en
            stor fotoutställning på Nybrokajen i Stockholm i höst. Bland
            vinnarbilderna utser vi dessutom en förstapristagare.
          </p>
          <b style={{ marginTop: 20, marginBottom: 10 }}>
            Vinn en lyxig SPA-vistelse
          </b>
          <p>
            Förstapristagaren vinner en övernattning för två personer i ett
            härligt rum med utsikt på valfritt ESS-hotell i Sverige, inklusive
            trerätters middag, spabehandlingar, pool club och frukost, till ett
            värde av 6 000–9 000 kronor.
          </p>
          <p>
            Alla landskapsvinnare får en egen livboj från Trygg-Hansa med valfri
            text (värde ca 1700 kronor) och ett presentkort på valfritt
            ESS-hotell i Sverige till ett värde av 1000 kronor.
          </p>
        </div>
      </div>
      <div className="block">
        <div className="content">
          <h2>Så här tävlar du</h2>
          <p>
            För att vara med och tävla skickar du in en bild med Trygg-Hansas
            livboj i fokus. Kom ihåg att juryn bedömer den kreativa höjden, så
            känn dig inte låst! Livbojen kan skildras i olika miljöer, årstider,
            med människor eller varför inte med ditt husdjur?
          </p>
          <p>
            Om du tar ner livbojen vid fototillfället, kom ihåg att hänga
            tillbaka den på sin plats – den är placerad på specifika platser för
            att alltid vara lättillgänglig. Bilden du tävlar med får inte vara
            äldre än ett år. För att du ska ha chans att vinna måste bilden vara
            oss tillhanda senast 11 augusti 2024.
          </p>

          <div
            className="thbtn"
            onClick={() => {
              navigate("/laddaupp");
            }}
          >
            Tävla nu!
          </div>
        </div>
      </div>

      <div className="block">
        <div className="content">
          <h2>Dela din berättelse</h2>
          <p>
            När du skickar in din bild får du gärna berätta mer om varför du har
            valt att fota just den livbojen. Kanske har du räddat någon med
            livbojen? Kanske är det vid en badplats där du eller någon i din
            familj lärde dig att simma? Kanske är platsen viktig för dig av
            andra anledningar? Skriv en kort motivering.
          </p>
        </div>
      </div>
      <div className="block">
        <div className="content">
          <h2>Juryn</h2>
          <p>
            I juryn som utser de vinnande bidragen återfinns flera bekanta
            ansikten med unik kunskap om simning, vattensäkerhet och inte minst
            livbojen. Juryn består av Svenska Livräddningssällskapets
            generalsekreterare Mikael Olausson, Anders “Järnmannen” Olsson,
            paralympisk guldmedaljör i simning, Johanna Forsström, tredje
            generation på Fristad Plast AB, Josefin Lundmark, kommunikations-
            och marknadsdirektör, Trygg-Hansa och Annika Persson, vd och
            skadedirektör Trygg-Hansa, som även är juryns ordförande.
          </p>
          {/*  <div className="grouprow">
            <img src={g1} />
            <img src={g2} />
          </div>
          <p className="who">
            Från vänster: Annika Persson, Anders Olsson, Johanna Forsström.
            Mikael Olausson, Paul Hansen och Josefin Lundmark.
          </p>*/}
        </div>
      </div>
      <div className="block white">
        <div className="content ">
          <h2>FAQ</h2>
          <Accordion defaultActiveKey="-1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Vilka är tävlingsreglerna?</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li> Bilden måste föreställa en livboj från Trygg-Hansa.</li>
                  <li>
                    Eventuella personer som syns i bild måste ha gett sitt
                    godkännande.
                  </li>
                  <li>Bilden får inte vara äldre än ett år.</li>
                  <li>
                    För att bilden ska kunna godkännas behöver den vara i hög
                    upplösning.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Hur bedöms mitt bidrag?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Efter tävlingsperiodens slut väljs ett foto per landskap ut
                  som vinnare. Av dessa 25 vinnare kommer även en (1)
                  förstapristagare att utses. Samtliga vinnare utses av en jury
                  bestående av juryns ordförande Annika Persson, vd och
                  skadedirektör Trygg-Hansa, Josefin Lundmark, kommunikations-
                  och marknadsdirektör Trygg-Hansa, Mikael Olausson,
                  generalsekreterare Svenska Livräddningssällskapet, Johanna
                  Forsström, Fristad Plast och Anders “Järnmannen” Olsson,
                  paralympisk guldmedaljör i simning.
                </p>
                <p>
                  För att bilderna ska kunna bedömas är det viktigt att de
                  uppfyller tävlingsreglerna. Vinnarna utses genom bedömning av
                  bidragets/fotots kreativa höjd och kvalité, hur väl livbojen
                  framträder i bilden, kommunikativt värde, säkerhetsaspekter,
                  kopplingen till vattensäkerhet samt vattenaktiviteter. Även
                  den korta berättelsen om livbojen på bilden kan vägas in i
                  bedömningen. Läs mer i{" "}
                  <span
                    onClick={() => {
                      setShowTerms(true);
                    }}
                  >
                    tävlingsvillkoren.
                  </span>
                  .
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Vad ska bilden föreställa?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Fotot måste innehålla en väl synlig livboj från Trygg-Hansa.
                  Utöver detta är det upp till fotografen själv att välja vilka
                  element som ska finnas i bilden. Viktigt att ha i åtanke är
                  att aldrig flytta på en livboj – den är placerad på specifika
                  platser för att alltid vara lättillgänglig. Tar du ner
                  livbojen vid fototillfället är det därför viktigt att du
                  hänger tillbaka den på sin plats. Eventuella personer som syns
                  i fotot måste ha gett sitt godkännande till att vara med på
                  fotot och till att fotot är med i tävlingen. Det finns inga
                  specifikationer kring vilket mått bilden ska ha, men maxmåttet
                  för utställningen är 100x200 cm, så vill du fylla hela ytan är
                  det viktigt att tänka på proportionerna. Det är upp till
                  fotografen att välja om fotot ska vara stående eller liggande.
                </p>
                <p>
                  Fotot får inte innehålla integritetskänslig information. Fotot
                  får inte heller innehålla något som kan uppfattas som stötande
                  (såsom, men inte uteslutande, nakenhet, våld eller vapen),
                  eller innehålla politiska eller religiösa budskap eller i
                  övrigt strida mot gällande lagar och regler i Sverige. Fotot
                  får inte vara äldre än ett år. För att fotot ska kunna
                  godkännas behöver bilden vara i hög upplösning. Läs mer i{" "}
                  <span
                    onClick={() => {
                      setShowTerms(true);
                    }}
                  >
                    tävlingsvillkoren.
                  </span>
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Hur gammal måste jag vara för att tävla?
              </Accordion.Header>
              <Accordion.Body>
                För att delta i tävlingen måste du bo och vara folkbokförd i
                Sverige, ha fyllt 18 år vid tävlingens start samt acceptera
                villkoren för tävlingen. Läs mer i{" "}
                <span
                  onClick={() => {
                    setShowTerms(true);
                  }}
                >
                  tävlingsvillkoren.
                </span>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Vad vinner jag?</Accordion.Header>
              <Accordion.Body>
                Samtliga 25 vinnare får en personlig livboj inklusive frakt
                (värde ca 1700 kronor) och ett presentkort på valfritt
                ESS-hotell i Sverige till ett värde av 1000 kronor. De vinnande
                fotografierna kommer visas på en utställning under september
                2024 på Nybrokajen i Stockholm. Den tävlande som utses till
                förstapristagare med det bästa fotot vinner en övernattning för
                två personer på valfritt ESS-hotell i Sverige, inklusive
                trerätters middag, två SPA-behandlingar (max 3 000 kr, i mån av
                tillgänglighet och utbud på det valda hotellet) och pool club,
                (i den mån tillgänglighet finns) och frukost, till ett värde av
                ca 6 000 – 9 000 kr (i mån av utbud och tillgänglighet). Giltigt
                1 år, alla dagar, dock ej bokningsbart 6 juli till och med 6
                augusti 2025. Resa till och från hotellet betalas av vinnaren.
                Juryns beslut kan inte överklagas. Det är inte möjligt att byta
                ut vinsterna mot kontanter eller andra varor. Läs mer i{" "}
                <span
                  onClick={() => {
                    setShowTerms(true);
                  }}
                >
                  tävlingsvillkoren.
                </span>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Hur många bidrag kan jag skicka in?
              </Accordion.Header>
              <Accordion.Body>
                Det är möjligt att delta med valfritt antal foton. Du måste ha
                tagit fotografierna själv. Läs mer i{" "}
                <span
                  onClick={() => {
                    setShowTerms(true);
                  }}
                >
                  tävlingsvillkoren.
                </span>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Hur kommer mitt bidrag att användas?
              </Accordion.Header>
              <Accordion.Body>
                Vinnarna i tävlingen ger Trygg-Hansa rätt att ställa ut bilderna
                på Nybrokajen i Stockholm. Trygg-Hansa kommer även att publicera
                bilderna som vinner tävlingen i interna och externa kanaler
                (pressmeddelande och inlägg i sociala medier) i samband med och
                efter tävlingen. Läs mer i{" "}
                <span
                  onClick={() => {
                    setShowTerms(true);
                  }}
                >
                  tävlingsvillkoren.
                </span>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                När äger utställningen på Nybrokajen rum?
              </Accordion.Header>
              <Accordion.Body>
                Fotoutställningen visas på Nybrokajen i Stockholm torsdag 26
                september till och med söndag 6 oktober 2024. Utställningen är
                öppen för allmänheten.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Hur länge pågår tävlingen?</Accordion.Header>
              <Accordion.Body>
                Tävlingen pågår under perioden måndag 3 juni 2024 till och med
                söndag 11 augusti 2024. Läs mer i{" "}
                <span
                  onClick={() => {
                    setShowTerms(true);
                  }}
                >
                  tävlingsvillkoren
                </span>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      {/* <div className="block last">
        <div className="content"></div>
      </div>*/}
      {showTerms && (
        <TermsView
          closeTerms={() => {
            setShowTerms(false);
          }}
        />
      )}
    </div>
  );
};
export default Start;
