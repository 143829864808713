import React, { useState, useEffect } from "react";

import withRouter from "../global/withRouter";
import { Routes, Route } from "react-router-dom";

import Start from "../start/Start";
import Upload from "../upload/Upload";

const RoutesRoot = (props) => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Start />} />
        <Route exact path="/laddaupp" element={<Upload />} />
      </Routes>
    </>
  );
};

export default withRouter(RoutesRoot);
