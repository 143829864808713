import React, { useState, useEffect, useMemo } from "react";
import useMeasure from "react-use-measure";
import { useTransition, a } from "@react-spring/web";
import { shuffle } from "lodash";

import useMedia from "./useMedia";

import b1 from "../../../assets/masonry/1.jpg";
import b2 from "../../../assets/masonry/2.jpg";
import b3 from "../../../assets/masonry/3.jpg";
import b4 from "../../../assets/masonry/4.jpg";
import b5 from "../../../assets/masonry/5.jpg";
import b6 from "../../../assets/masonry/6.jpg";
import b7 from "../../../assets/masonry/7.jpg";
import b8 from "../../../assets/masonry/8.jpg";
import b9 from "../../../assets/masonry/9.jpg";
import b10 from "../../../assets/masonry/10.jpg";
import b11 from "../../../assets/masonry/11.jpg";
import b12 from "../../../assets/masonry/12.jpg";
import b13 from "../../../assets/masonry/13.jpg";
import b14 from "../../../assets/masonry/14.jpg";
import b15 from "../../../assets/masonry/15.jpg";
import b16 from "../../../assets/masonry/16.jpg";
import b17 from "../../../assets/masonry/17.jpg";
import b18 from "../../../assets/masonry/18.jpg";
import b19 from "../../../assets/masonry/19.jpg";
import b20 from "../../../assets/masonry/20.jpg";
import b21 from "../../../assets/masonry/21.jpg";

const Masonry = () => {
  // Hook1: Tie media queries to the number of columns
  const columns = useMedia(
    ["(min-width: 1500px)", "(min-width: 1000px)", "(min-width: 600px)"],
    [5, 5, 5],
    5,
  );
  // Hook2: Measure the width of the container element
  const [ref, { width }] = useMeasure();
  const rnd = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const [imgs, setImgs] = useState(
    shuffle([
      b1,
      b2,
      b3,
      b4,
      b5,
      b6,
      b7,
      b8,
      b9,
      b10,
      b11,
      b12,
      b13,
      b14,
      b15,
      b16,
      b17,
      b18,
      b19,
      b20,
      b21,
    ]),
  );

  const data = [
    {
      i: 0,
      height: 600,
    },
    {
      i: 1,
      height: 600,
    },
    {
      i: 2,
      height: 600,
    },
    {
      i: 3,
      height: 600,
    },
    {
      i: 4,
      height: 600,
    },
    {
      i: 5,
      height: 600,
    },
    {
      i: 6,
      height: 600,
    },
    {
      i: 7,
      height: 600,
    },
  ];

  const [items, set] = useState(data);

  useEffect(() => {
    imgs.map((image) => {
      new Image().src = image;
    });
    const t = setInterval(() => {
      set(shuffle);
      setTimeout(() => {
        let ims = shuffle([...imgs]);
        setImgs(ims);
      }, 200);
    }, 4000);
    return () => clearInterval(t);
  }, []);

  const [heights, gridItems] = useMemo(() => {
    let heights = new Array(columns).fill(0);
    let gridItems = items.map((child, i) => {
      const column = heights.indexOf(Math.min(...heights));
      let x = (width / columns) * column;

      if (i >= 5) {
        x = (width / columns) * (i - 4);
      }
      let y = 0;

      let hei = width / columns;
      if (i === 0 || i === 4) {
        y = (heights[column] += hei) - hei / 4.5;
      } else if (i === 2 || i === 6) {
        y = (heights[column] += hei) - hei / 2.5;
      } else {
        y = (heights[column] += hei) - hei / 1.5;
      }

      return { ...child, x, y: y - 100, width: width / columns, height: hei };
    });
    return [heights, gridItems];
  }, [columns, items, width]);

  const transitions = useTransition(gridItems, {
    key: (item) => item.i,
    from: ({ x, y, width, height }) => {
      return {
        x,
        y,
        width,
        height: width,
        opacity: 0,
      };
    },
    enter: (x, y, width, height) => {
      return { x, y, width, height: width, opacity: 1 };
    },

    update: ({ x, y, width, height }) => ({ x, y, width, height: width }),
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  });
  // Render the grid
  return (
    <div ref={ref} className="masonry" style={{ height: Math.max(...heights) }}>
      {transitions((style, item) => {
        return (
          <a.div style={style}>
            <div
              style={{
                backgroundImage: `url(${imgs[item.i]})`,
              }}
            />
          </a.div>
        );
      })}
    </div>
  );
};

export default Masonry;
