import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { isMobile } from "react-device-detect";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (isMobile) {
  document.body.classList.add("Mobile");
} else {
  document.body.classList.remove("Mobile");
}

// let h = window.screen.availHeight - 300;
// let w = window.screen.availWidth;
// document.body.style.height = h + "px";
// document.body.style.minHeight = h + "px";

//<BrowserRouter basename="/livboj_v2">
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
