import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import add from "../../assets/add.svg";

const Terms = (props) => {
  const { closeTerms } = props;
  const navigate = useNavigate();

  return (
    <div className="termsView">
      <div className="termscontent">
        <h1 style={{ textAlign: "center" }}>Tävlingsvillkor</h1>
        <p className="sub" style={{ textAlign: "center" }}>
          För att delta i Trygg-Hansas fototävling behöver tävlingsvillkoren och
          reglerna godkännas
        </p>
        <ol type="1">
          <li>
            Genom att delta i tävlingen
            <ol type="a" className="section">
              <li>
                godkänner du tävlingens villkor och regler som framgår nedan
                samt bekräftar att du tagit del av informationen om
                personuppgiftsbehandlingen enligt punkt 7 nedan samt informerat
                eventuella personer som förekommer i bild om
                personuppgiftsbehandlingen.
              </li>
              <li>garanterar du att du har tagit fotografiet själv.</li>
              <li>
                lämnar du ditt samtycke till att ditt namn kan komma att
                offentliggöras i samband med kommunikation kring finalist- och
                vinnarbidrag.
              </li>
            </ol>
          </li>
          <li>
            Ansvarig för tävlingen och personuppgiftsbehandlingen är:
            <br />
            Trygg-Hansa Försäkring filial, org.nr 516403–8662 (Trygg-Hansa)
            <br />
            106 26 Stockholm
            <br />
            Telefon 077 – 111 11 10
          </li>

          <li>
            För att delta i tävlingen måste du bo och vara folkbokförd i
            Sverige, ha fyllt 18 år vid tävlingens start samt acceptera
            villkoren för tävlingen.
          </li>
          <li>
            Tävlingsbidragen ska skickas in under perioden måndag 3 juni till
            och med söndag 11 augusti 2024.
          </li>
          <li>
            Genom att utses till vinnare
            <ol type="a" className="section">
              <li>
                ger du Trygg-Hansa rätt att ställa ut vinnande bidrag på
                Nybrokajen i Stockholm.
              </li>
              <li>
                låter du Trygg-Hansa att i samband med tävlingen samt en tid
                därefter, publicera ditt foto i marknadsföringskommunikation
                kring denna fototävling. Publicering kan ske till exempel när
                vinnande bidrag presenteras och kommuniceras i Trygg-Hansas
                interna och externa kanaler (t.ex. pressmeddelande, sociala
                medier). Rätten gäller till och med den 31 december 2025.
              </li>
              <li>
                lämnar du ditt samtycke till att Trygg-Hansa använder ditt namn
                och bild, i våra kanaler enligt lag (1978:800) om namn och bild
                i reklam.
              </li>
              <li>
                garanterar du att du har inhämtat samtycke från samtliga
                personer som eventuellt förekommer i bild till att medverka i
                sammanhang där Trygg-Hansa publicerar bilden (till exempel
                pressmeddelande, sociala medier samt interna kanaler).
              </li>
              <li>
                lämnar du ditt samtycke till att ditt namn kan komma att
                offentliggöras i samband med kommunikation kring finalist- och
                vinnarbidragen.
              </li>
            </ol>
          </li>
          <li>
            Tävlingsregler
            <ol type="a" className="section">
              <li>
                Anställda på Trygg-Hansa, anställda på Trygg-Hansas
                samarbetspartners JMWGolin och Perfect Fools som är eller har
                varit involverade i arbetet med denna tävling, jurymedlemmar
                samt boende i samma hushåll som dessa får inte delta i
                tävlingen. Anställda på Trygg-Hansa bjuds in till en intern
                tävling med chans att vinna en utställningsplats på Nybrokajen.
              </li>
              <li>
                Det är tillåtet att delta med flera foton. Fotot måste innehålla
                en väl synlig livboj från Trygg-Hansa. Eventuella personer som
                syns i fotot måste ha gett sitt godkännande till att vara med på
                fotot och till att fotot är med i tävlingen. Fotot får inte vara
                äldre än ett år. Fotot behöver vara i högupplöst format.
              </li>
              <li>
                När du skickar in ditt foto får du gärna berätta mer om varför
                du har valt att fota just den livbojen. Kanske har du räddat
                någon med livbojen? Kanske är det vid en badplats där du eller
                någon i din familj lärde dig att simma? Kanske är platsen viktig
                för dig av andra anledningar? Tänk dock på att inte ange några
                känsliga personuppgifter, så som uppgifter om hälsotillstånd, i
                berättelsen. Att lämna en berättelse är inte ett villkor för att
                delta i tävlingen.
              </li>
              <li>
                Tävlingsbidrag skickas via hemsidan där bidraget måste innehålla
                deltagarens (fotografens) namn, telefonnummer, e-postadress,
                ålder samt uppgift om i vilket landskap livbojen på fotot finns.
                Namn och kontaktuppgifter (namn, e-post samt telefonnummer) ska
                även tillhandahållas för samtliga eventuella personer som syns
                på fotot.
              </li>
              <li>
                Trygg-Hansa tar inte ansvar för eventuella tekniska svårigheter
                eller andra problem som kan påverka endera anmälan/inlämning
                eller slutresultatet för denna tävling.
              </li>
              <li>
                Fotot får inte innehålla integritetskänslig information. Fotot
                får inte heller innehålla något som kan uppfattas som stötande
                (så som, men inte uteslutande, nakenhet, våld eller vapen),
                eller innehålla politiska eller religiösa budskap eller i övrigt
                strida mot gällande lagar och regler i Sverige.
              </li>
              <li>
                Efter tävlingsperiodens slut väljs ett foto per landskap ut som
                vinnare. Av dessa 25 vinnare kommer även en (1) förstapristagare
                att utses. Samtliga vinnare utses av en jury bestående av Annika
                Persson, vd och skadedirektör Trygg-Hansa, Josefin Lundmark,
                kommunikations- och marknadsdirektör Trygg-Hansa, Mikael
                Olausson, generalsekreterare Svenska Livräddningssällskapet,
                Johanna Forsström, Fristad Plast och Anders ”Järnmannen” Olsson,
                paralympisk guldmedaljör i simning. Vinnarna utses genom
                bedömning av bidragets/fotots kreativa höjd och kvalité, hur väl
                livbojen framträder i fotot, kommunikativt värde,
                säkerhetsaspekter, kopplingen till vattensäkerhet samt
                vattenaktiviteter. Juryns beslut kan inte överklagas.
              </li>
              <li>
                Samtliga 25 vinnare får en personlig livboj inklusive frakt
                (värde ca 1 700 kr) och ett presentkort på valfritt ESS-hotell
                till ett värde av 1000 kronor. De vinnande fotografierna visas
                på en utställning under september 2024 på Nybrokajen i
                Stockholm.
              </li>
              <li>
                Den tävlande som utses till förstapristagare med det bästa fotot
                vinner en övernattning för två personer på valfritt ESS-hotell i
                Sverige, inklusive trerätters middag, två SPA-behandlingar (max
                3 000 kr, i mån av tillgänglighet och utbud på det valda
                hotellet) och pool club (i den mån tillgänglighet finns), och
                frukost, till ett sammanlagt värde av ca 6&nbsp;000 – 9&nbsp;000 kr (i mån
                av utbud och tillgänglighet). Giltigt 1 år, alla dagar, dock ej
                bokningsbart 6 juli till och med 6 augusti 2025. Resa till och
                från hotellet betalas av vinnaren.
              </li>
              <li>
                Det är inte möjligt att byta ut vinsterna mot kontanter eller
                andra varor. Trygg-Hansa levererar kostnadsfritt livbojen till
                adress inom Sverige enligt överenskommelse med respektive
                vinnare.
              </li>
              <li>
                Vinnaren ansvarar själv för att betala eventuell vinstskatt.
              </li>
              <li>
                Vinnarna kommer att kontaktas personligen via e-post eller
                telefon efter tävlingsperiodens slut. Om Trygg-Hansa inte fått
                svar från vinnaren inom en vecka från första kontaktförsöket
                kommer en ny vinnare utses i stället för den som inte svarat.
                Vinst förutsätter att eventuella andra personer som förekommer i
                fotot har gett sitt skriftliga samtycke till Trygg-Hansa till
                att medverka i reklamsammanhang för Trygg-Hansa. Samtycket ska
                inom en vecka från beskedet om juryns beslut lämnas på särskild
                blankett som tillhandahålls av Trygg-Hansa.
              </li>
            </ol>
          </li>

          <li>
            Behandling av personuppgifter
            <br />
            <p>
              Trygg-Hansa är personuppgiftsansvarig för behandlingen av
              personuppgifter. Personuppgifterna behandlas för olika ändamål
            </p>
            <span>
              <i>För att bedriva marknadsföring</i>
              <p>
                De kategorier av personuppgifter som behandlas är
                kontaktuppgifter (t.ex. namn, adress, telefonnummer,
                e-postadress) samt tävlingsbidraget (foto och eventuell
                berättelse). Personuppgifterna inhämtas av dig som
                tävlingsdeltagare.
              </p>

              <p>
                Personuppgifterna behandlas för att Trygg-Hansa ska kunna
                administrera aktuell tävling vilket bl.a. innebär att utse
                vinnare, utge vinst samt att använda fotot i enlighet med
                tävlingsvillkoren.
              </p>
              <p>
                Behandling av personuppgifter avseende tävlingsdeltagaren samt
                eventuella personer kopplade till vinnande bidrag är nödvändig
                för att fullgöra avtal.
              </p>
              <p>
                Den lagliga grunden för behandlingen av övriga personuppgifter
                är intresseavvägning eftersom vi bedömer att vårt intresse av
                att kunna bedriva marknadsföring av Trygg-Hansa genom aktuell
                tävling överväger de registrerades intresse av skydd för sina
                personuppgifter.
              </p>
              <p>
                Personuppgifter kopplade till bidrag som inte utgör vinnande
                bidrag kommer att behandlas under tiden som tävlingen pågår och
                raderas senast tre månader efter tävlingens slut.
              </p>
              <p>
                Personuppgifter kopplade till någon av de vinnande bidragen
                kommer att behandlas så länge fotot används i
                marknadsföringssyfte genom kommunikation kring denna tävling.
                Dock längst till och med 31 december 2025.
              </p>
              <i>För att fullgöra en rättslig förpliktelse</i>
              <p>
                De kategorier av personuppgifter som behandlas är
                kontaktuppgifter (t.ex. namn, adress, telefonnummer,
                e-postadress), tävlingsbidraget (foto och eventuell berättelse).
                Personuppgifterna inhämtas av dig som tävlingsdeltagare.
              </p>
              <p>
                Personuppgifterna behandlas för att Trygg-Hansa ska säkerställa
                lagstadgat samtycke till att medverka i reklamsammanhang för
                Trygg-Hansa, enligt lag (1978:800) om namn och bild i reklam,
                från samtliga personer som förekommer på fotografiet.
              </p>
              <p>
                Behandlingen är nödvändig för att fullgöra en rättslig
                förpliktelse som åvilar Trygg-Hansa.
              </p>
              <p>
                Personuppgifter kopplade till bidrag som inte utgör vinnande
                bidrag kommer att behandlas under tiden som tävlingen pågår och
                raderas senast tre månader efter tävlingens slut.
              </p>
              <p>
                Personuppgifter kopplade till någon av de vinnande bidragen
                kommer att behandlas så länge fotot används i
                marknadsföringssyfte genom kommunikation kring denna tävling.
                Dock längst till och med 31 december 2025.
              </p>
              <i>För att försvara och ta tillvara rättsliga anspråk</i>
              <p>
                De kategorier av personuppgifter som behandlas är
                kontaktuppgifter (t.ex. namn, adress, telefonnummer
                e-postadress), tävlingsbidraget (foto och eventuell berättelse).
                Personuppgifterna inhämtas av dig som tävlingsdeltagare.
              </p>
              <p>
                Den lagliga grunden för behandlingen är intresseavvägning
                eftersom vi bedömer att vårt intresse av att kunna försvara och
                ta tillvara rättsliga anspråk överväger de registrerades
                intresse av skydd för sina personuppgifter.
              </p>
              <p>
                Personuppgifter kopplade till bidrag som inte utgör vinnande
                bidrag kommer att behandlas under tiden som tävlingen pågår och
                raderas senast tre månader efter tävlingens slut.
              </p>
              <p>
                Personuppgifter kopplade till någon av de vinnande bidragen
                kommer att behandlas så länge fotot används i
                marknadsföringssyfte genom kommunikation kring denna tävling.
                Dock längst till och med 31 december 2025.
              </p>
            </span>
          </li>
        </ol>
        <p>
          Trygg-Hansa kan komma att lämna ut dina personuppgifter till tredje
          parter för olika ändamål, t.ex. till medlemmar av juryn samt till
          tjänsteleverantörer och samarbetspartners inom marknadsföring.
        </p>
        <p>
          Registrerade har rätt att begära tillgång till, rättelse eller
          radering av personuppgifter. Registrerade har vidare rätt att begära
          begränsning av behandlingen samt att invända mot behandlingen. Se mer
          i Trygg-Hansas integritetspolicy{" "}
          <a
            href="https://www.trygghansa.se/om-trygghansa/om-webbplatsen/personuppgifter"
            target="_blank"
          >
            Information om personuppgifter | Trygg-Hansa (trygghansa.se)
          </a>{" "}
          under avsnitt 3 ”Dina rättigheter”.
        </p>
        <p>
          Tveka inte att kontakta oss på Trygg-Hansa om du har några frågor om
          behandlingen av dina personuppgifter, vår användning av dina
          personuppgifter eller om du vill använda dig av dina rättigheter som
          är beskrivna i avsnittet 3 "Dina Rättigheter".
        </p>
        <p>
          Om du önskar utnyttja en av dina rättigheter kan du kontakta oss per
          post, märk kuvertet med <b>"Registerutdrag"</b>;
        </p>
        <p>
          Postadress:
          <br />
          Trygg-Hansa
          <br /> 106 26 Stockholm
        </p>
        <p>
          Du kan även skicka e-post till{" "}
          <a href="mailto:registerutdrag@trygghansa.se" target="_blank">
            registerutdrag@trygghansa.se
          </a>
          , skriv <b>"Registerutdrag"</b> i ämnesraden.
        </p>
        <p>
          För specifika frågor till Dataskyddsombudet så kan du skriva till;{" "}
          <a href="mailto:dpo@trygghansa.se" target="_blank">
            dpo@trygghansa.se
          </a>
        </p>
        <img
          src={add}
          onClick={() => {
            closeTerms();
          }}
          className="close"
        />
      </div>
    </div>
  );
};

export default Terms;
